import React, { useEffect, useState, useContext } from "react";
import LoadingBlock from "shared/LoadingBlock";
import { Link, useNavigate } from "react-router-dom";
import HeadTags from "shared/HeadTags";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import "swiper/css";
import ym from "react-yandex-metrika";
import parse from "html-react-parser";
import {
  useGetCertificatesListQuery,
  useGetEmployeesListQuery,
  useGetGalleryListQuery,
  useGetMainPageTextQuery,
} from "services/api/api";
import { ViewportContext } from 'app/viewportContext';

const personalImg = require("assets/img/mainpage_personal_img.jpg");
const banner = require("assets/img/banner_122024.jpg");
const smBanner1 = require("assets/img/banner_122024sm1.jpg");
const smBanner2 = require("assets/img/banner_122024sm2.jpg");
const smBanner3 = require("assets/img/banner_122024sm3.jpg");

const HomePage = ({openPopup}: {openPopup: (state: any) => void}) => {
  const navigate = useNavigate();
  const { data: employees, isLoading: employeesLoading } =
    useGetEmployeesListQuery();
  const { data: certificates, isLoading: certificatesLoading } =
    useGetCertificatesListQuery();
  const { data: gallery, isLoading: galleryLoading } = useGetGalleryListQuery();
  const { data: texts, isLoading: textsLoading } = useGetMainPageTextQuery();
  const [galleryIndex, setGalleryIndex] = useState(-1);
  let bigImages =
    gallery && gallery[0]
      ? gallery[0].images.map((el) => {
          return { src: el.image };
        })
      : [];
  const handleGalleryClick = (index: number) => setGalleryIndex(index);
  const [certificateIndex, setCertificateIndex] = useState(-1);
  let bigCertificatesImages = certificates
    ? certificates.map((el) => {
        return { src: el.image };
      })
    : [];
  const handleCertificateClick = (index: number) => setCertificateIndex(index);

  const [employeesIndexes, setEmployeesIndexes] = useState([]);
  
  const breakpoint = 480;
	const width = useContext(ViewportContext);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (employees) {
      let array = [];
      employees.forEach((_) => {
        array.push(false);
      });
      setEmployeesIndexes(array);
    }
  }, [employees]);

  useEffect(() => {
    ym("hit", "/");
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [navigate]);

  useEffect(() => {
		setIsMobile(width <= breakpoint);		
	}, [width]);

  return (
    <LoadingBlock isLoading={textsLoading}>
      <>
        <HeadTags
          title={
            texts && texts.seo?.title
              ? texts.seo.title
              : 'Салон красоты "Граф Ягода"'
          }
          metaDescription={
            texts && texts.seo?.description
              ? texts.seo.description
              : "Первый в России салон красоты с авторским массажем, спа для волос и эндосферой."
          }
        />
        <div className="mainpage">
          <div className="mainslider">
            <Swiper
              className="mainpage-slider"
              modules={[Navigation, Pagination]}
              slidesPerView={1}
              pagination
              // navigation={{
              //   nextEl: "#main-next",
              //   prevEl: "#main-prev",
              // }}
              loop
            >              
              <SwiperSlide>
                <div className="main-slider_elem main-slide">
                  <div className="main-slide_content container">
                    <div className="main-slide_title">
                      <h1>{texts && parse(texts?.main_slide_title)}</h1>
                      <div className="main-slide_sub">
                        {texts && parse(texts?.main_slide_sub_title)}
                      </div>
                    </div>
                    <div className="main-slide_desc">
                      {texts?.main_slide_desc}
                    </div>
                    <div className="main-slide_btns">
                      <Link to="/uslugi">Выбрать услугу</Link>
                      <Link to="/ceny">Ознакомиться с ценами</Link>
                    </div>
                  </div>
                </div>
              </SwiperSlide>              
            </Swiper>
            {/* <div className="slider-prev" id="main-prev"></div>
            <div className="slider-next" id="main-next"></div> */}
          </div>

          {isMobile  
					? (
						<Swiper
							className="banner-slider"
							modules={[Pagination]}
							spaceBetween={10}
							slidesPerView={1}
							pagination
							loop
							lazy
						>						
							<SwiperSlide >
							  <div>
								<img
								  alt={"Успеть до Нового Года - слайд 1"}
								  src={smBanner1}
								  title={"Успеть до Нового Года"}
								/>
							  </div>
							</SwiperSlide>
							<SwiperSlide >
							  <div>
								<img
								  alt={"Успеть до Нового Года - слайд 2"}
								  src={smBanner2}
								  title={"Успеть до Нового Года"}
								/>
							  </div>
							</SwiperSlide>
							<SwiperSlide >
							  <div>
								<img
								  alt={"Успеть до Нового Года - слайд 3"}
								  src={smBanner3}
								  title={"Успеть до Нового Года"}
								/>
							  </div>
							</SwiperSlide>
					  </Swiper>
					) 
					: (<div className="banner-block">
						<img src={banner} alt="Уникальная программа - Успеть до Нового Года" title="Успеть до Нового Года" />
					</div>)}

          <div className="vacancy-slide">
            <div className="container">
              <div className="vacancy-slide_content">
                <div className="vacancy-slide_row">
                  <div className="vacancy-slide_title">
                    <h2>Приглашаем<br/> мастеров<br/> присоединиться<br/> к нашей команде!</h2>
                  </div>
                  <div className="vacancy-slide_text">
                    <p>
                      Мы ищем массажистов, парикмахеров, косметологов,
                      мастеров ногтевого сервиса, а&nbsp;также клининг-персонал для
                      поддержания чистоты и порядка в нашем салоне.
                    </p>
                    <p className="vacancy-slide_desktop">
                      Если вы профессионал своего дела и хотите развиваться в
                      сфере красоты и здоровья, то мы будем рады видеть вас&nbsp;в
                      нашей команде!
                    </p>
                    <p className="vacancy-slide_paragraph">
                      Запись на собеседование по телефону&nbsp;
                      <a href="tel:+79103316774">+7 (910)331-67-74</a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="mainpage_about">
            <div className="container">
              <h2>{texts?.mainpage_about_h2}</h2>
              <div className="mainpage_about__text">
                <div className="mainpage_about__inner">
                  {texts?.mainpage_about_text &&
                    parse(texts?.mainpage_about_text)}
                </div>
              </div>
              <LoadingBlock isLoading={galleryLoading}>
                <div className="mainpage_about__slider">
                  {gallery ? (
                    gallery.length ? (
                      <>
                        <Swiper
                          className="photo-slider"
                          modules={[Navigation, Pagination]}
                          spaceBetween={10}
                          slidesPerView={2}
                          pagination
                          navigation={{
                            nextEl: "#photo-next",
                            prevEl: "#photo-prev",
                          }}
                          breakpoints={{
                            "300": {
                              slidesPerView: 2,
                              spaceBetween: 10,
                            },
                            "768": {
                              slidesPerView: 3,
                              spaceBetween: 25,
                            },
                            "1280": {
                              slidesPerView: 4,
                            },
                          }}
                          loop
                        >
                          {gallery[0].images.map((item, i) => {
                            return (
                              <SwiperSlide key={i}>
                                <div onClick={() => handleGalleryClick(i)}>
                                  <img
                                    alt={gallery[0].title}
                                    src={item.thumbnail}
                                    title="фото салона"
                                  />
                                </div>
                              </SwiperSlide>
                            );
                          })}
                        </Swiper>
                        <div className="slider-prev" id="photo-prev"></div>
                        <div className="slider-next" id="photo-next"></div>
                      </>
                    ) : null
                  ) : (
                    <p>Загрузка ...</p>
                  )}
                </div>
              </LoadingBlock>
            </div>
          </div>
          <div className="mainpage_personal gray">
            <div className="container">
              <h2>{texts?.mainpage_personal_h2}</h2>
              <div className="mainpage_personal__sub">Гордость нашего салона - это наша команда мастеров</div>
              <div className="mainpage_personal__content">
                <div className="mainpage_personal__img">
                  <img src={personalImg} alt="Наши специалисты" />
                </div>
                <div className="mainpage_personal__text">
                  <p>Мастера нашего салона красоты - это виртуозы своего дела, которые <b>создают неповторимые образы</b> и 
                  превращают каждый визит клиента в незабываемое приключение. Они обладают 
                  уникальными <b>навыками и талантом</b>, позволяющими подчеркнуть индивидуальность каждого клиента.</p>
                  <p>Наши мастера внимательно <b>прислушиваются к пожеланиям</b> и предпочтениям клиента, создавая 
                    гармоничные и стильные образы, которые подчеркнут Ваши достоинства. 
                    Специалисты <b>заботятся о каждом клиенте</b>, создавая атмосферу <b>уюта и доверия</b>, что 
                    позволяет посетителям чувствовать себя уверенно и наслаждаться процессом преображения.</p>
                </div>
              </div>
              <div className="mainpage_personal__billet">
                <div className="mainpage_personal__billet-inner">
                  Наши мастера - это настоящие волшебники, которые помогут Вам стать ещё прекраснее и увереннее в себе. 
                </div>
              </div>
              {/* {texts?.mainpage_about_text &&
                parse(texts?.mainpage_personal_text)} */}
              <LoadingBlock isLoading={employeesLoading}>
                <ul className="mainpage_personal__list">
                  {employees ? (
                    employees.length ? (
                      <>
                        {employees.map((person, i) => {
                          return (
                            <li key={person.id}>
                              <div
                                className="mainpage_personal__link"
                                onClick={() =>
                                  setEmployeesIndexes(
                                    employeesIndexes.map((el, ndx) => {
                                      return ndx === i ? true : false;
                                    })
                                  )
                                }
                              >
                                <span className="mainpage_personal__photo">
                                  <img
                                    src={person.thumbnail}
                                    alt={person.name}
                                  />
                                </span>
                                <span className="mainpage_personal__name">
                                  {person.name}
                                </span>
                                <span className="mainpage_personal__post">
                                  {person.job_title}
                                </span>
                                <span className="mainpage_personal__experience">
                                  стаж работы {person.experience} лет
                                </span>
                              </div>
                              {person.gallery.length > 0 ? (
                                <Lightbox
                                  slides={person.gallery.map((el) => {
                                    return { src: el.image };
                                  })}
                                  open={employeesIndexes[i]}
                                  close={() =>
                                    setEmployeesIndexes(
                                      employeesIndexes.map((el) => {
                                        return false;
                                      })
                                    )
                                  }
                                  className="employees_gallery"
                                />
                              ) : null}
                              <div className="mainpage_personal__controls">
                                <button className="mainpage_personal__btn" onClick={() => openPopup(person.id)}>Записаться</button>
                              </div>
                            </li>
                          );
                        })}
                      </>
                    ) : null
                  ) : (
                    <p>Загрузка ...</p>
                  )}
                </ul>
              </LoadingBlock>
            </div>
          </div>
          <div className="mainpage_license">
            <div className="container">
              <h2>Лицензии и сертификаты</h2>
              <LoadingBlock isLoading={certificatesLoading}>
                <div className="mainpage_license__slider">
                  {certificates ? (
                    certificates.length ? (
                      <>
                        <Swiper
                          className="license-slider"
                          modules={[Navigation, Pagination]}
                          spaceBetween={10}
                          slidesPerView={2}
                          pagination
                          navigation={{
                            nextEl: "#license-next",
                            prevEl: "#license-prev",
                          }}
                          breakpoints={{
                            "300": {
                              slidesPerView: 2,
                              spaceBetween: 0,
                            },
                            "768": {
                              slidesPerView: 3,
                              spaceBetween: 20,
                            },
                            "1140": {
                              slidesPerView: 4,
                              spaceBetween: 25,
                            },
                          }}
                          loop
                          lazy
                        >
                          {certificates.map((certificate, i) => {
                            return (
                              <SwiperSlide key={certificate.id}>
                                <div onClick={() => handleCertificateClick(i)}>
                                  <img
                                    alt={certificate.name}
                                    src={certificate.image}
                                    title={certificate.name}
                                  />
                                </div>
                              </SwiperSlide>
                            );
                          })}
                        </Swiper>
                        <div className="slider-prev" id="license-prev"></div>
                        <div className="slider-next" id="license-next"></div>
                      </>
                    ) : null
                  ) : (
                    <p>Загрузка ...</p>
                  )}
                </div>
              </LoadingBlock>
              <div className="mainpage_license_btns">
                <Link to="/uslugi">Выбрать услугу</Link>
                <Link to="/ceny">Ознакомиться с ценами</Link>
              </div>
            </div>
          </div>
          <Lightbox
            slides={bigCertificatesImages}
            open={certificateIndex >= 0}
            index={certificateIndex}
            close={() => setCertificateIndex(-1)}
          />
          <Lightbox
            slides={bigImages}
            open={galleryIndex >= 0}
            index={galleryIndex}
            close={() => setGalleryIndex(-1)}
          />
        </div>
      </>
    </LoadingBlock>
  );
};
export default HomePage;
